// Selects all codicon names encapsulated in the `$()` syntax and wraps the
// results with spaces so that screen readers can read the text better.
export function getCodiconAriaLabel(text) {
    if (!text) {
        return '';
    }
    return text.replace(/\$\((.*?)\)/g, (_match, codiconName) => ` ${codiconName} `).trim();
}
/**
 * The Codicon library is a set of default icons that are built-in in VS Code.
 *
 * In the product (outside of base) Codicons should only be used as defaults. In order to have all icons in VS Code
 * themeable, component should define new, UI component specific icons using `iconRegistry.registerIcon`.
 * In that call a Codicon can be named as default.
 */
export class Codicon {
    constructor(id, definition, description) {
        this.id = id;
        this.definition = definition;
        this.description = description;
        Codicon._allCodicons.push(this);
    }
    get classNames() { return 'codicon codicon-' + this.id; }
    // classNamesArray is useful for migrating to ES6 classlist
    get classNamesArray() { return ['codicon', 'codicon-' + this.id]; }
    get cssSelector() { return '.codicon.codicon-' + this.id; }
    /**
     * @returns Returns all default icons covered by the codicon font. Only to be used by the icon registry in platform.
     */
    static getAll() {
        return Codicon._allCodicons;
    }
}
// registry
Codicon._allCodicons = [];
// built-in icons, with image name
Codicon.add = new Codicon('add', { fontCharacter: '\\ea60' });
Codicon.plus = new Codicon('plus', Codicon.add.definition);
Codicon.gistNew = new Codicon('gist-new', Codicon.add.definition);
Codicon.repoCreate = new Codicon('repo-create', Codicon.add.definition);
Codicon.lightbulb = new Codicon('lightbulb', { fontCharacter: '\\ea61' });
Codicon.lightBulb = new Codicon('light-bulb', { fontCharacter: '\\ea61' });
Codicon.repo = new Codicon('repo', { fontCharacter: '\\ea62' });
Codicon.repoDelete = new Codicon('repo-delete', { fontCharacter: '\\ea62' });
Codicon.gistFork = new Codicon('gist-fork', { fontCharacter: '\\ea63' });
Codicon.repoForked = new Codicon('repo-forked', { fontCharacter: '\\ea63' });
Codicon.gitPullRequest = new Codicon('git-pull-request', { fontCharacter: '\\ea64' });
Codicon.gitPullRequestAbandoned = new Codicon('git-pull-request-abandoned', { fontCharacter: '\\ea64' });
Codicon.recordKeys = new Codicon('record-keys', { fontCharacter: '\\ea65' });
Codicon.keyboard = new Codicon('keyboard', { fontCharacter: '\\ea65' });
Codicon.tag = new Codicon('tag', { fontCharacter: '\\ea66' });
Codicon.tagAdd = new Codicon('tag-add', { fontCharacter: '\\ea66' });
Codicon.tagRemove = new Codicon('tag-remove', { fontCharacter: '\\ea66' });
Codicon.person = new Codicon('person', { fontCharacter: '\\ea67' });
Codicon.personFollow = new Codicon('person-follow', { fontCharacter: '\\ea67' });
Codicon.personOutline = new Codicon('person-outline', { fontCharacter: '\\ea67' });
Codicon.personFilled = new Codicon('person-filled', { fontCharacter: '\\ea67' });
Codicon.gitBranch = new Codicon('git-branch', { fontCharacter: '\\ea68' });
Codicon.gitBranchCreate = new Codicon('git-branch-create', { fontCharacter: '\\ea68' });
Codicon.gitBranchDelete = new Codicon('git-branch-delete', { fontCharacter: '\\ea68' });
Codicon.sourceControl = new Codicon('source-control', { fontCharacter: '\\ea68' });
Codicon.mirror = new Codicon('mirror', { fontCharacter: '\\ea69' });
Codicon.mirrorPublic = new Codicon('mirror-public', { fontCharacter: '\\ea69' });
Codicon.star = new Codicon('star', { fontCharacter: '\\ea6a' });
Codicon.starAdd = new Codicon('star-add', { fontCharacter: '\\ea6a' });
Codicon.starDelete = new Codicon('star-delete', { fontCharacter: '\\ea6a' });
Codicon.starEmpty = new Codicon('star-empty', { fontCharacter: '\\ea6a' });
Codicon.comment = new Codicon('comment', { fontCharacter: '\\ea6b' });
Codicon.commentAdd = new Codicon('comment-add', { fontCharacter: '\\ea6b' });
Codicon.alert = new Codicon('alert', { fontCharacter: '\\ea6c' });
Codicon.warning = new Codicon('warning', { fontCharacter: '\\ea6c' });
Codicon.search = new Codicon('search', { fontCharacter: '\\ea6d' });
Codicon.searchSave = new Codicon('search-save', { fontCharacter: '\\ea6d' });
Codicon.logOut = new Codicon('log-out', { fontCharacter: '\\ea6e' });
Codicon.signOut = new Codicon('sign-out', { fontCharacter: '\\ea6e' });
Codicon.logIn = new Codicon('log-in', { fontCharacter: '\\ea6f' });
Codicon.signIn = new Codicon('sign-in', { fontCharacter: '\\ea6f' });
Codicon.eye = new Codicon('eye', { fontCharacter: '\\ea70' });
Codicon.eyeUnwatch = new Codicon('eye-unwatch', { fontCharacter: '\\ea70' });
Codicon.eyeWatch = new Codicon('eye-watch', { fontCharacter: '\\ea70' });
Codicon.circleFilled = new Codicon('circle-filled', { fontCharacter: '\\ea71' });
Codicon.primitiveDot = new Codicon('primitive-dot', { fontCharacter: '\\ea71' });
Codicon.closeDirty = new Codicon('close-dirty', { fontCharacter: '\\ea71' });
Codicon.debugBreakpoint = new Codicon('debug-breakpoint', { fontCharacter: '\\ea71' });
Codicon.debugBreakpointDisabled = new Codicon('debug-breakpoint-disabled', { fontCharacter: '\\ea71' });
Codicon.debugHint = new Codicon('debug-hint', { fontCharacter: '\\ea71' });
Codicon.primitiveSquare = new Codicon('primitive-square', { fontCharacter: '\\ea72' });
Codicon.edit = new Codicon('edit', { fontCharacter: '\\ea73' });
Codicon.pencil = new Codicon('pencil', { fontCharacter: '\\ea73' });
Codicon.info = new Codicon('info', { fontCharacter: '\\ea74' });
Codicon.issueOpened = new Codicon('issue-opened', { fontCharacter: '\\ea74' });
Codicon.gistPrivate = new Codicon('gist-private', { fontCharacter: '\\ea75' });
Codicon.gitForkPrivate = new Codicon('git-fork-private', { fontCharacter: '\\ea75' });
Codicon.lock = new Codicon('lock', { fontCharacter: '\\ea75' });
Codicon.mirrorPrivate = new Codicon('mirror-private', { fontCharacter: '\\ea75' });
Codicon.close = new Codicon('close', { fontCharacter: '\\ea76' });
Codicon.removeClose = new Codicon('remove-close', { fontCharacter: '\\ea76' });
Codicon.x = new Codicon('x', { fontCharacter: '\\ea76' });
Codicon.repoSync = new Codicon('repo-sync', { fontCharacter: '\\ea77' });
Codicon.sync = new Codicon('sync', { fontCharacter: '\\ea77' });
Codicon.clone = new Codicon('clone', { fontCharacter: '\\ea78' });
Codicon.desktopDownload = new Codicon('desktop-download', { fontCharacter: '\\ea78' });
Codicon.beaker = new Codicon('beaker', { fontCharacter: '\\ea79' });
Codicon.microscope = new Codicon('microscope', { fontCharacter: '\\ea79' });
Codicon.vm = new Codicon('vm', { fontCharacter: '\\ea7a' });
Codicon.deviceDesktop = new Codicon('device-desktop', { fontCharacter: '\\ea7a' });
Codicon.file = new Codicon('file', { fontCharacter: '\\ea7b' });
Codicon.fileText = new Codicon('file-text', { fontCharacter: '\\ea7b' });
Codicon.more = new Codicon('more', { fontCharacter: '\\ea7c' });
Codicon.ellipsis = new Codicon('ellipsis', { fontCharacter: '\\ea7c' });
Codicon.kebabHorizontal = new Codicon('kebab-horizontal', { fontCharacter: '\\ea7c' });
Codicon.mailReply = new Codicon('mail-reply', { fontCharacter: '\\ea7d' });
Codicon.reply = new Codicon('reply', { fontCharacter: '\\ea7d' });
Codicon.organization = new Codicon('organization', { fontCharacter: '\\ea7e' });
Codicon.organizationFilled = new Codicon('organization-filled', { fontCharacter: '\\ea7e' });
Codicon.organizationOutline = new Codicon('organization-outline', { fontCharacter: '\\ea7e' });
Codicon.newFile = new Codicon('new-file', { fontCharacter: '\\ea7f' });
Codicon.fileAdd = new Codicon('file-add', { fontCharacter: '\\ea7f' });
Codicon.newFolder = new Codicon('new-folder', { fontCharacter: '\\ea80' });
Codicon.fileDirectoryCreate = new Codicon('file-directory-create', { fontCharacter: '\\ea80' });
Codicon.trash = new Codicon('trash', { fontCharacter: '\\ea81' });
Codicon.trashcan = new Codicon('trashcan', { fontCharacter: '\\ea81' });
Codicon.history = new Codicon('history', { fontCharacter: '\\ea82' });
Codicon.clock = new Codicon('clock', { fontCharacter: '\\ea82' });
Codicon.folder = new Codicon('folder', { fontCharacter: '\\ea83' });
Codicon.fileDirectory = new Codicon('file-directory', { fontCharacter: '\\ea83' });
Codicon.symbolFolder = new Codicon('symbol-folder', { fontCharacter: '\\ea83' });
Codicon.logoGithub = new Codicon('logo-github', { fontCharacter: '\\ea84' });
Codicon.markGithub = new Codicon('mark-github', { fontCharacter: '\\ea84' });
Codicon.github = new Codicon('github', { fontCharacter: '\\ea84' });
Codicon.terminal = new Codicon('terminal', { fontCharacter: '\\ea85' });
Codicon.console = new Codicon('console', { fontCharacter: '\\ea85' });
Codicon.repl = new Codicon('repl', { fontCharacter: '\\ea85' });
Codicon.zap = new Codicon('zap', { fontCharacter: '\\ea86' });
Codicon.symbolEvent = new Codicon('symbol-event', { fontCharacter: '\\ea86' });
Codicon.error = new Codicon('error', { fontCharacter: '\\ea87' });
Codicon.stop = new Codicon('stop', { fontCharacter: '\\ea87' });
Codicon.variable = new Codicon('variable', { fontCharacter: '\\ea88' });
Codicon.symbolVariable = new Codicon('symbol-variable', { fontCharacter: '\\ea88' });
Codicon.array = new Codicon('array', { fontCharacter: '\\ea8a' });
Codicon.symbolArray = new Codicon('symbol-array', { fontCharacter: '\\ea8a' });
Codicon.symbolModule = new Codicon('symbol-module', { fontCharacter: '\\ea8b' });
Codicon.symbolPackage = new Codicon('symbol-package', { fontCharacter: '\\ea8b' });
Codicon.symbolNamespace = new Codicon('symbol-namespace', { fontCharacter: '\\ea8b' });
Codicon.symbolObject = new Codicon('symbol-object', { fontCharacter: '\\ea8b' });
Codicon.symbolMethod = new Codicon('symbol-method', { fontCharacter: '\\ea8c' });
Codicon.symbolFunction = new Codicon('symbol-function', { fontCharacter: '\\ea8c' });
Codicon.symbolConstructor = new Codicon('symbol-constructor', { fontCharacter: '\\ea8c' });
Codicon.symbolBoolean = new Codicon('symbol-boolean', { fontCharacter: '\\ea8f' });
Codicon.symbolNull = new Codicon('symbol-null', { fontCharacter: '\\ea8f' });
Codicon.symbolNumeric = new Codicon('symbol-numeric', { fontCharacter: '\\ea90' });
Codicon.symbolNumber = new Codicon('symbol-number', { fontCharacter: '\\ea90' });
Codicon.symbolStructure = new Codicon('symbol-structure', { fontCharacter: '\\ea91' });
Codicon.symbolStruct = new Codicon('symbol-struct', { fontCharacter: '\\ea91' });
Codicon.symbolParameter = new Codicon('symbol-parameter', { fontCharacter: '\\ea92' });
Codicon.symbolTypeParameter = new Codicon('symbol-type-parameter', { fontCharacter: '\\ea92' });
Codicon.symbolKey = new Codicon('symbol-key', { fontCharacter: '\\ea93' });
Codicon.symbolText = new Codicon('symbol-text', { fontCharacter: '\\ea93' });
Codicon.symbolReference = new Codicon('symbol-reference', { fontCharacter: '\\ea94' });
Codicon.goToFile = new Codicon('go-to-file', { fontCharacter: '\\ea94' });
Codicon.symbolEnum = new Codicon('symbol-enum', { fontCharacter: '\\ea95' });
Codicon.symbolValue = new Codicon('symbol-value', { fontCharacter: '\\ea95' });
Codicon.symbolRuler = new Codicon('symbol-ruler', { fontCharacter: '\\ea96' });
Codicon.symbolUnit = new Codicon('symbol-unit', { fontCharacter: '\\ea96' });
Codicon.activateBreakpoints = new Codicon('activate-breakpoints', { fontCharacter: '\\ea97' });
Codicon.archive = new Codicon('archive', { fontCharacter: '\\ea98' });
Codicon.arrowBoth = new Codicon('arrow-both', { fontCharacter: '\\ea99' });
Codicon.arrowDown = new Codicon('arrow-down', { fontCharacter: '\\ea9a' });
Codicon.arrowLeft = new Codicon('arrow-left', { fontCharacter: '\\ea9b' });
Codicon.arrowRight = new Codicon('arrow-right', { fontCharacter: '\\ea9c' });
Codicon.arrowSmallDown = new Codicon('arrow-small-down', { fontCharacter: '\\ea9d' });
Codicon.arrowSmallLeft = new Codicon('arrow-small-left', { fontCharacter: '\\ea9e' });
Codicon.arrowSmallRight = new Codicon('arrow-small-right', { fontCharacter: '\\ea9f' });
Codicon.arrowSmallUp = new Codicon('arrow-small-up', { fontCharacter: '\\eaa0' });
Codicon.arrowUp = new Codicon('arrow-up', { fontCharacter: '\\eaa1' });
Codicon.bell = new Codicon('bell', { fontCharacter: '\\eaa2' });
Codicon.bold = new Codicon('bold', { fontCharacter: '\\eaa3' });
Codicon.book = new Codicon('book', { fontCharacter: '\\eaa4' });
Codicon.bookmark = new Codicon('bookmark', { fontCharacter: '\\eaa5' });
Codicon.debugBreakpointConditionalUnverified = new Codicon('debug-breakpoint-conditional-unverified', { fontCharacter: '\\eaa6' });
Codicon.debugBreakpointConditional = new Codicon('debug-breakpoint-conditional', { fontCharacter: '\\eaa7' });
Codicon.debugBreakpointConditionalDisabled = new Codicon('debug-breakpoint-conditional-disabled', { fontCharacter: '\\eaa7' });
Codicon.debugBreakpointDataUnverified = new Codicon('debug-breakpoint-data-unverified', { fontCharacter: '\\eaa8' });
Codicon.debugBreakpointData = new Codicon('debug-breakpoint-data', { fontCharacter: '\\eaa9' });
Codicon.debugBreakpointDataDisabled = new Codicon('debug-breakpoint-data-disabled', { fontCharacter: '\\eaa9' });
Codicon.debugBreakpointLogUnverified = new Codicon('debug-breakpoint-log-unverified', { fontCharacter: '\\eaaa' });
Codicon.debugBreakpointLog = new Codicon('debug-breakpoint-log', { fontCharacter: '\\eaab' });
Codicon.debugBreakpointLogDisabled = new Codicon('debug-breakpoint-log-disabled', { fontCharacter: '\\eaab' });
Codicon.briefcase = new Codicon('briefcase', { fontCharacter: '\\eaac' });
Codicon.broadcast = new Codicon('broadcast', { fontCharacter: '\\eaad' });
Codicon.browser = new Codicon('browser', { fontCharacter: '\\eaae' });
Codicon.bug = new Codicon('bug', { fontCharacter: '\\eaaf' });
Codicon.calendar = new Codicon('calendar', { fontCharacter: '\\eab0' });
Codicon.caseSensitive = new Codicon('case-sensitive', { fontCharacter: '\\eab1' });
Codicon.check = new Codicon('check', { fontCharacter: '\\eab2' });
Codicon.checklist = new Codicon('checklist', { fontCharacter: '\\eab3' });
Codicon.chevronDown = new Codicon('chevron-down', { fontCharacter: '\\eab4' });
Codicon.dropDownButton = new Codicon('drop-down-button', Codicon.chevronDown.definition);
Codicon.chevronLeft = new Codicon('chevron-left', { fontCharacter: '\\eab5' });
Codicon.chevronRight = new Codicon('chevron-right', { fontCharacter: '\\eab6' });
Codicon.chevronUp = new Codicon('chevron-up', { fontCharacter: '\\eab7' });
Codicon.chromeClose = new Codicon('chrome-close', { fontCharacter: '\\eab8' });
Codicon.chromeMaximize = new Codicon('chrome-maximize', { fontCharacter: '\\eab9' });
Codicon.chromeMinimize = new Codicon('chrome-minimize', { fontCharacter: '\\eaba' });
Codicon.chromeRestore = new Codicon('chrome-restore', { fontCharacter: '\\eabb' });
Codicon.circleOutline = new Codicon('circle-outline', { fontCharacter: '\\eabc' });
Codicon.debugBreakpointUnverified = new Codicon('debug-breakpoint-unverified', { fontCharacter: '\\eabc' });
Codicon.circleSlash = new Codicon('circle-slash', { fontCharacter: '\\eabd' });
Codicon.circuitBoard = new Codicon('circuit-board', { fontCharacter: '\\eabe' });
Codicon.clearAll = new Codicon('clear-all', { fontCharacter: '\\eabf' });
Codicon.clippy = new Codicon('clippy', { fontCharacter: '\\eac0' });
Codicon.closeAll = new Codicon('close-all', { fontCharacter: '\\eac1' });
Codicon.cloudDownload = new Codicon('cloud-download', { fontCharacter: '\\eac2' });
Codicon.cloudUpload = new Codicon('cloud-upload', { fontCharacter: '\\eac3' });
Codicon.code = new Codicon('code', { fontCharacter: '\\eac4' });
Codicon.collapseAll = new Codicon('collapse-all', { fontCharacter: '\\eac5' });
Codicon.colorMode = new Codicon('color-mode', { fontCharacter: '\\eac6' });
Codicon.commentDiscussion = new Codicon('comment-discussion', { fontCharacter: '\\eac7' });
Codicon.compareChanges = new Codicon('compare-changes', { fontCharacter: '\\eafd' });
Codicon.creditCard = new Codicon('credit-card', { fontCharacter: '\\eac9' });
Codicon.dash = new Codicon('dash', { fontCharacter: '\\eacc' });
Codicon.dashboard = new Codicon('dashboard', { fontCharacter: '\\eacd' });
Codicon.database = new Codicon('database', { fontCharacter: '\\eace' });
Codicon.debugContinue = new Codicon('debug-continue', { fontCharacter: '\\eacf' });
Codicon.debugDisconnect = new Codicon('debug-disconnect', { fontCharacter: '\\ead0' });
Codicon.debugPause = new Codicon('debug-pause', { fontCharacter: '\\ead1' });
Codicon.debugRestart = new Codicon('debug-restart', { fontCharacter: '\\ead2' });
Codicon.debugStart = new Codicon('debug-start', { fontCharacter: '\\ead3' });
Codicon.debugStepInto = new Codicon('debug-step-into', { fontCharacter: '\\ead4' });
Codicon.debugStepOut = new Codicon('debug-step-out', { fontCharacter: '\\ead5' });
Codicon.debugStepOver = new Codicon('debug-step-over', { fontCharacter: '\\ead6' });
Codicon.debugStop = new Codicon('debug-stop', { fontCharacter: '\\ead7' });
Codicon.debug = new Codicon('debug', { fontCharacter: '\\ead8' });
Codicon.deviceCameraVideo = new Codicon('device-camera-video', { fontCharacter: '\\ead9' });
Codicon.deviceCamera = new Codicon('device-camera', { fontCharacter: '\\eada' });
Codicon.deviceMobile = new Codicon('device-mobile', { fontCharacter: '\\eadb' });
Codicon.diffAdded = new Codicon('diff-added', { fontCharacter: '\\eadc' });
Codicon.diffIgnored = new Codicon('diff-ignored', { fontCharacter: '\\eadd' });
Codicon.diffModified = new Codicon('diff-modified', { fontCharacter: '\\eade' });
Codicon.diffRemoved = new Codicon('diff-removed', { fontCharacter: '\\eadf' });
Codicon.diffRenamed = new Codicon('diff-renamed', { fontCharacter: '\\eae0' });
Codicon.diff = new Codicon('diff', { fontCharacter: '\\eae1' });
Codicon.discard = new Codicon('discard', { fontCharacter: '\\eae2' });
Codicon.editorLayout = new Codicon('editor-layout', { fontCharacter: '\\eae3' });
Codicon.emptyWindow = new Codicon('empty-window', { fontCharacter: '\\eae4' });
Codicon.exclude = new Codicon('exclude', { fontCharacter: '\\eae5' });
Codicon.extensions = new Codicon('extensions', { fontCharacter: '\\eae6' });
Codicon.eyeClosed = new Codicon('eye-closed', { fontCharacter: '\\eae7' });
Codicon.fileBinary = new Codicon('file-binary', { fontCharacter: '\\eae8' });
Codicon.fileCode = new Codicon('file-code', { fontCharacter: '\\eae9' });
Codicon.fileMedia = new Codicon('file-media', { fontCharacter: '\\eaea' });
Codicon.filePdf = new Codicon('file-pdf', { fontCharacter: '\\eaeb' });
Codicon.fileSubmodule = new Codicon('file-submodule', { fontCharacter: '\\eaec' });
Codicon.fileSymlinkDirectory = new Codicon('file-symlink-directory', { fontCharacter: '\\eaed' });
Codicon.fileSymlinkFile = new Codicon('file-symlink-file', { fontCharacter: '\\eaee' });
Codicon.fileZip = new Codicon('file-zip', { fontCharacter: '\\eaef' });
Codicon.files = new Codicon('files', { fontCharacter: '\\eaf0' });
Codicon.filter = new Codicon('filter', { fontCharacter: '\\eaf1' });
Codicon.flame = new Codicon('flame', { fontCharacter: '\\eaf2' });
Codicon.foldDown = new Codicon('fold-down', { fontCharacter: '\\eaf3' });
Codicon.foldUp = new Codicon('fold-up', { fontCharacter: '\\eaf4' });
Codicon.fold = new Codicon('fold', { fontCharacter: '\\eaf5' });
Codicon.folderActive = new Codicon('folder-active', { fontCharacter: '\\eaf6' });
Codicon.folderOpened = new Codicon('folder-opened', { fontCharacter: '\\eaf7' });
Codicon.gear = new Codicon('gear', { fontCharacter: '\\eaf8' });
Codicon.gift = new Codicon('gift', { fontCharacter: '\\eaf9' });
Codicon.gistSecret = new Codicon('gist-secret', { fontCharacter: '\\eafa' });
Codicon.gist = new Codicon('gist', { fontCharacter: '\\eafb' });
Codicon.gitCommit = new Codicon('git-commit', { fontCharacter: '\\eafc' });
Codicon.gitCompare = new Codicon('git-compare', { fontCharacter: '\\eafd' });
Codicon.gitMerge = new Codicon('git-merge', { fontCharacter: '\\eafe' });
Codicon.githubAction = new Codicon('github-action', { fontCharacter: '\\eaff' });
Codicon.githubAlt = new Codicon('github-alt', { fontCharacter: '\\eb00' });
Codicon.globe = new Codicon('globe', { fontCharacter: '\\eb01' });
Codicon.grabber = new Codicon('grabber', { fontCharacter: '\\eb02' });
Codicon.graph = new Codicon('graph', { fontCharacter: '\\eb03' });
Codicon.gripper = new Codicon('gripper', { fontCharacter: '\\eb04' });
Codicon.heart = new Codicon('heart', { fontCharacter: '\\eb05' });
Codicon.home = new Codicon('home', { fontCharacter: '\\eb06' });
Codicon.horizontalRule = new Codicon('horizontal-rule', { fontCharacter: '\\eb07' });
Codicon.hubot = new Codicon('hubot', { fontCharacter: '\\eb08' });
Codicon.inbox = new Codicon('inbox', { fontCharacter: '\\eb09' });
Codicon.issueClosed = new Codicon('issue-closed', { fontCharacter: '\\eba4' });
Codicon.issueReopened = new Codicon('issue-reopened', { fontCharacter: '\\eb0b' });
Codicon.issues = new Codicon('issues', { fontCharacter: '\\eb0c' });
Codicon.italic = new Codicon('italic', { fontCharacter: '\\eb0d' });
Codicon.jersey = new Codicon('jersey', { fontCharacter: '\\eb0e' });
Codicon.json = new Codicon('json', { fontCharacter: '\\eb0f' });
Codicon.kebabVertical = new Codicon('kebab-vertical', { fontCharacter: '\\eb10' });
Codicon.key = new Codicon('key', { fontCharacter: '\\eb11' });
Codicon.law = new Codicon('law', { fontCharacter: '\\eb12' });
Codicon.lightbulbAutofix = new Codicon('lightbulb-autofix', { fontCharacter: '\\eb13' });
Codicon.linkExternal = new Codicon('link-external', { fontCharacter: '\\eb14' });
Codicon.link = new Codicon('link', { fontCharacter: '\\eb15' });
Codicon.listOrdered = new Codicon('list-ordered', { fontCharacter: '\\eb16' });
Codicon.listUnordered = new Codicon('list-unordered', { fontCharacter: '\\eb17' });
Codicon.liveShare = new Codicon('live-share', { fontCharacter: '\\eb18' });
Codicon.loading = new Codicon('loading', { fontCharacter: '\\eb19' });
Codicon.location = new Codicon('location', { fontCharacter: '\\eb1a' });
Codicon.mailRead = new Codicon('mail-read', { fontCharacter: '\\eb1b' });
Codicon.mail = new Codicon('mail', { fontCharacter: '\\eb1c' });
Codicon.markdown = new Codicon('markdown', { fontCharacter: '\\eb1d' });
Codicon.megaphone = new Codicon('megaphone', { fontCharacter: '\\eb1e' });
Codicon.mention = new Codicon('mention', { fontCharacter: '\\eb1f' });
Codicon.milestone = new Codicon('milestone', { fontCharacter: '\\eb20' });
Codicon.mortarBoard = new Codicon('mortar-board', { fontCharacter: '\\eb21' });
Codicon.move = new Codicon('move', { fontCharacter: '\\eb22' });
Codicon.multipleWindows = new Codicon('multiple-windows', { fontCharacter: '\\eb23' });
Codicon.mute = new Codicon('mute', { fontCharacter: '\\eb24' });
Codicon.noNewline = new Codicon('no-newline', { fontCharacter: '\\eb25' });
Codicon.note = new Codicon('note', { fontCharacter: '\\eb26' });
Codicon.octoface = new Codicon('octoface', { fontCharacter: '\\eb27' });
Codicon.openPreview = new Codicon('open-preview', { fontCharacter: '\\eb28' });
Codicon.package_ = new Codicon('package', { fontCharacter: '\\eb29' });
Codicon.paintcan = new Codicon('paintcan', { fontCharacter: '\\eb2a' });
Codicon.pin = new Codicon('pin', { fontCharacter: '\\eb2b' });
Codicon.play = new Codicon('play', { fontCharacter: '\\eb2c' });
Codicon.run = new Codicon('run', { fontCharacter: '\\eb2c' });
Codicon.plug = new Codicon('plug', { fontCharacter: '\\eb2d' });
Codicon.preserveCase = new Codicon('preserve-case', { fontCharacter: '\\eb2e' });
Codicon.preview = new Codicon('preview', { fontCharacter: '\\eb2f' });
Codicon.project = new Codicon('project', { fontCharacter: '\\eb30' });
Codicon.pulse = new Codicon('pulse', { fontCharacter: '\\eb31' });
Codicon.question = new Codicon('question', { fontCharacter: '\\eb32' });
Codicon.quote = new Codicon('quote', { fontCharacter: '\\eb33' });
Codicon.radioTower = new Codicon('radio-tower', { fontCharacter: '\\eb34' });
Codicon.reactions = new Codicon('reactions', { fontCharacter: '\\eb35' });
Codicon.references = new Codicon('references', { fontCharacter: '\\eb36' });
Codicon.refresh = new Codicon('refresh', { fontCharacter: '\\eb37' });
Codicon.regex = new Codicon('regex', { fontCharacter: '\\eb38' });
Codicon.remoteExplorer = new Codicon('remote-explorer', { fontCharacter: '\\eb39' });
Codicon.remote = new Codicon('remote', { fontCharacter: '\\eb3a' });
Codicon.remove = new Codicon('remove', { fontCharacter: '\\eb3b' });
Codicon.replaceAll = new Codicon('replace-all', { fontCharacter: '\\eb3c' });
Codicon.replace = new Codicon('replace', { fontCharacter: '\\eb3d' });
Codicon.repoClone = new Codicon('repo-clone', { fontCharacter: '\\eb3e' });
Codicon.repoForcePush = new Codicon('repo-force-push', { fontCharacter: '\\eb3f' });
Codicon.repoPull = new Codicon('repo-pull', { fontCharacter: '\\eb40' });
Codicon.repoPush = new Codicon('repo-push', { fontCharacter: '\\eb41' });
Codicon.report = new Codicon('report', { fontCharacter: '\\eb42' });
Codicon.requestChanges = new Codicon('request-changes', { fontCharacter: '\\eb43' });
Codicon.rocket = new Codicon('rocket', { fontCharacter: '\\eb44' });
Codicon.rootFolderOpened = new Codicon('root-folder-opened', { fontCharacter: '\\eb45' });
Codicon.rootFolder = new Codicon('root-folder', { fontCharacter: '\\eb46' });
Codicon.rss = new Codicon('rss', { fontCharacter: '\\eb47' });
Codicon.ruby = new Codicon('ruby', { fontCharacter: '\\eb48' });
Codicon.saveAll = new Codicon('save-all', { fontCharacter: '\\eb49' });
Codicon.saveAs = new Codicon('save-as', { fontCharacter: '\\eb4a' });
Codicon.save = new Codicon('save', { fontCharacter: '\\eb4b' });
Codicon.screenFull = new Codicon('screen-full', { fontCharacter: '\\eb4c' });
Codicon.screenNormal = new Codicon('screen-normal', { fontCharacter: '\\eb4d' });
Codicon.searchStop = new Codicon('search-stop', { fontCharacter: '\\eb4e' });
Codicon.server = new Codicon('server', { fontCharacter: '\\eb50' });
Codicon.settingsGear = new Codicon('settings-gear', { fontCharacter: '\\eb51' });
Codicon.settings = new Codicon('settings', { fontCharacter: '\\eb52' });
Codicon.shield = new Codicon('shield', { fontCharacter: '\\eb53' });
Codicon.smiley = new Codicon('smiley', { fontCharacter: '\\eb54' });
Codicon.sortPrecedence = new Codicon('sort-precedence', { fontCharacter: '\\eb55' });
Codicon.splitHorizontal = new Codicon('split-horizontal', { fontCharacter: '\\eb56' });
Codicon.splitVertical = new Codicon('split-vertical', { fontCharacter: '\\eb57' });
Codicon.squirrel = new Codicon('squirrel', { fontCharacter: '\\eb58' });
Codicon.starFull = new Codicon('star-full', { fontCharacter: '\\eb59' });
Codicon.starHalf = new Codicon('star-half', { fontCharacter: '\\eb5a' });
Codicon.symbolClass = new Codicon('symbol-class', { fontCharacter: '\\eb5b' });
Codicon.symbolColor = new Codicon('symbol-color', { fontCharacter: '\\eb5c' });
Codicon.symbolCustomColor = new Codicon('symbol-customcolor', { fontCharacter: '\\eb5c' });
Codicon.symbolConstant = new Codicon('symbol-constant', { fontCharacter: '\\eb5d' });
Codicon.symbolEnumMember = new Codicon('symbol-enum-member', { fontCharacter: '\\eb5e' });
Codicon.symbolField = new Codicon('symbol-field', { fontCharacter: '\\eb5f' });
Codicon.symbolFile = new Codicon('symbol-file', { fontCharacter: '\\eb60' });
Codicon.symbolInterface = new Codicon('symbol-interface', { fontCharacter: '\\eb61' });
Codicon.symbolKeyword = new Codicon('symbol-keyword', { fontCharacter: '\\eb62' });
Codicon.symbolMisc = new Codicon('symbol-misc', { fontCharacter: '\\eb63' });
Codicon.symbolOperator = new Codicon('symbol-operator', { fontCharacter: '\\eb64' });
Codicon.symbolProperty = new Codicon('symbol-property', { fontCharacter: '\\eb65' });
Codicon.wrench = new Codicon('wrench', { fontCharacter: '\\eb65' });
Codicon.wrenchSubaction = new Codicon('wrench-subaction', { fontCharacter: '\\eb65' });
Codicon.symbolSnippet = new Codicon('symbol-snippet', { fontCharacter: '\\eb66' });
Codicon.tasklist = new Codicon('tasklist', { fontCharacter: '\\eb67' });
Codicon.telescope = new Codicon('telescope', { fontCharacter: '\\eb68' });
Codicon.textSize = new Codicon('text-size', { fontCharacter: '\\eb69' });
Codicon.threeBars = new Codicon('three-bars', { fontCharacter: '\\eb6a' });
Codicon.thumbsdown = new Codicon('thumbsdown', { fontCharacter: '\\eb6b' });
Codicon.thumbsup = new Codicon('thumbsup', { fontCharacter: '\\eb6c' });
Codicon.tools = new Codicon('tools', { fontCharacter: '\\eb6d' });
Codicon.triangleDown = new Codicon('triangle-down', { fontCharacter: '\\eb6e' });
Codicon.triangleLeft = new Codicon('triangle-left', { fontCharacter: '\\eb6f' });
Codicon.triangleRight = new Codicon('triangle-right', { fontCharacter: '\\eb70' });
Codicon.triangleUp = new Codicon('triangle-up', { fontCharacter: '\\eb71' });
Codicon.twitter = new Codicon('twitter', { fontCharacter: '\\eb72' });
Codicon.unfold = new Codicon('unfold', { fontCharacter: '\\eb73' });
Codicon.unlock = new Codicon('unlock', { fontCharacter: '\\eb74' });
Codicon.unmute = new Codicon('unmute', { fontCharacter: '\\eb75' });
Codicon.unverified = new Codicon('unverified', { fontCharacter: '\\eb76' });
Codicon.verified = new Codicon('verified', { fontCharacter: '\\eb77' });
Codicon.versions = new Codicon('versions', { fontCharacter: '\\eb78' });
Codicon.vmActive = new Codicon('vm-active', { fontCharacter: '\\eb79' });
Codicon.vmOutline = new Codicon('vm-outline', { fontCharacter: '\\eb7a' });
Codicon.vmRunning = new Codicon('vm-running', { fontCharacter: '\\eb7b' });
Codicon.watch = new Codicon('watch', { fontCharacter: '\\eb7c' });
Codicon.whitespace = new Codicon('whitespace', { fontCharacter: '\\eb7d' });
Codicon.wholeWord = new Codicon('whole-word', { fontCharacter: '\\eb7e' });
Codicon.window = new Codicon('window', { fontCharacter: '\\eb7f' });
Codicon.wordWrap = new Codicon('word-wrap', { fontCharacter: '\\eb80' });
Codicon.zoomIn = new Codicon('zoom-in', { fontCharacter: '\\eb81' });
Codicon.zoomOut = new Codicon('zoom-out', { fontCharacter: '\\eb82' });
Codicon.listFilter = new Codicon('list-filter', { fontCharacter: '\\eb83' });
Codicon.listFlat = new Codicon('list-flat', { fontCharacter: '\\eb84' });
Codicon.listSelection = new Codicon('list-selection', { fontCharacter: '\\eb85' });
Codicon.selection = new Codicon('selection', { fontCharacter: '\\eb85' });
Codicon.listTree = new Codicon('list-tree', { fontCharacter: '\\eb86' });
Codicon.debugBreakpointFunctionUnverified = new Codicon('debug-breakpoint-function-unverified', { fontCharacter: '\\eb87' });
Codicon.debugBreakpointFunction = new Codicon('debug-breakpoint-function', { fontCharacter: '\\eb88' });
Codicon.debugBreakpointFunctionDisabled = new Codicon('debug-breakpoint-function-disabled', { fontCharacter: '\\eb88' });
Codicon.debugStackframeActive = new Codicon('debug-stackframe-active', { fontCharacter: '\\eb89' });
Codicon.circleSmallFilled = new Codicon('circle-small-filled', { fontCharacter: '\\eb8a' });
Codicon.debugStackframeDot = new Codicon('debug-stackframe-dot', Codicon.circleSmallFilled.definition);
Codicon.debugStackframe = new Codicon('debug-stackframe', { fontCharacter: '\\eb8b' });
Codicon.debugStackframeFocused = new Codicon('debug-stackframe-focused', { fontCharacter: '\\eb8b' });
Codicon.debugBreakpointUnsupported = new Codicon('debug-breakpoint-unsupported', { fontCharacter: '\\eb8c' });
Codicon.symbolString = new Codicon('symbol-string', { fontCharacter: '\\eb8d' });
Codicon.debugReverseContinue = new Codicon('debug-reverse-continue', { fontCharacter: '\\eb8e' });
Codicon.debugStepBack = new Codicon('debug-step-back', { fontCharacter: '\\eb8f' });
Codicon.debugRestartFrame = new Codicon('debug-restart-frame', { fontCharacter: '\\eb90' });
Codicon.callIncoming = new Codicon('call-incoming', { fontCharacter: '\\eb92' });
Codicon.callOutgoing = new Codicon('call-outgoing', { fontCharacter: '\\eb93' });
Codicon.menu = new Codicon('menu', { fontCharacter: '\\eb94' });
Codicon.expandAll = new Codicon('expand-all', { fontCharacter: '\\eb95' });
Codicon.feedback = new Codicon('feedback', { fontCharacter: '\\eb96' });
Codicon.groupByRefType = new Codicon('group-by-ref-type', { fontCharacter: '\\eb97' });
Codicon.ungroupByRefType = new Codicon('ungroup-by-ref-type', { fontCharacter: '\\eb98' });
Codicon.account = new Codicon('account', { fontCharacter: '\\eb99' });
Codicon.bellDot = new Codicon('bell-dot', { fontCharacter: '\\eb9a' });
Codicon.debugConsole = new Codicon('debug-console', { fontCharacter: '\\eb9b' });
Codicon.library = new Codicon('library', { fontCharacter: '\\eb9c' });
Codicon.output = new Codicon('output', { fontCharacter: '\\eb9d' });
Codicon.runAll = new Codicon('run-all', { fontCharacter: '\\eb9e' });
Codicon.syncIgnored = new Codicon('sync-ignored', { fontCharacter: '\\eb9f' });
Codicon.pinned = new Codicon('pinned', { fontCharacter: '\\eba0' });
Codicon.githubInverted = new Codicon('github-inverted', { fontCharacter: '\\eba1' });
Codicon.debugAlt = new Codicon('debug-alt', { fontCharacter: '\\eb91' });
Codicon.serverProcess = new Codicon('server-process', { fontCharacter: '\\eba2' });
Codicon.serverEnvironment = new Codicon('server-environment', { fontCharacter: '\\eba3' });
Codicon.pass = new Codicon('pass', { fontCharacter: '\\eba4' });
Codicon.stopCircle = new Codicon('stop-circle', { fontCharacter: '\\eba5' });
Codicon.playCircle = new Codicon('play-circle', { fontCharacter: '\\eba6' });
Codicon.record = new Codicon('record', { fontCharacter: '\\eba7' });
Codicon.debugAltSmall = new Codicon('debug-alt-small', { fontCharacter: '\\eba8' });
Codicon.vmConnect = new Codicon('vm-connect', { fontCharacter: '\\eba9' });
Codicon.cloud = new Codicon('cloud', { fontCharacter: '\\ebaa' });
Codicon.merge = new Codicon('merge', { fontCharacter: '\\ebab' });
Codicon.exportIcon = new Codicon('export', { fontCharacter: '\\ebac' });
Codicon.graphLeft = new Codicon('graph-left', { fontCharacter: '\\ebad' });
Codicon.magnet = new Codicon('magnet', { fontCharacter: '\\ebae' });
Codicon.notebook = new Codicon('notebook', { fontCharacter: '\\ebaf' });
Codicon.redo = new Codicon('redo', { fontCharacter: '\\ebb0' });
Codicon.checkAll = new Codicon('check-all', { fontCharacter: '\\ebb1' });
Codicon.pinnedDirty = new Codicon('pinned-dirty', { fontCharacter: '\\ebb2' });
Codicon.passFilled = new Codicon('pass-filled', { fontCharacter: '\\ebb3' });
Codicon.circleLargeFilled = new Codicon('circle-large-filled', { fontCharacter: '\\ebb4' });
Codicon.circleLargeOutline = new Codicon('circle-large-outline', { fontCharacter: '\\ebb5' });
Codicon.combine = new Codicon('combine', { fontCharacter: '\\ebb6' });
Codicon.gather = new Codicon('gather', { fontCharacter: '\\ebb6' });
Codicon.table = new Codicon('table', { fontCharacter: '\\ebb7' });
Codicon.variableGroup = new Codicon('variable-group', { fontCharacter: '\\ebb8' });
Codicon.typeHierarchy = new Codicon('type-hierarchy', { fontCharacter: '\\ebb9' });
Codicon.typeHierarchySub = new Codicon('type-hierarchy-sub', { fontCharacter: '\\ebba' });
Codicon.typeHierarchySuper = new Codicon('type-hierarchy-super', { fontCharacter: '\\ebbb' });
Codicon.gitPullRequestCreate = new Codicon('git-pull-request-create', { fontCharacter: '\\ebbc' });
Codicon.runAbove = new Codicon('run-above', { fontCharacter: '\\ebbd' });
Codicon.runBelow = new Codicon('run-below', { fontCharacter: '\\ebbe' });
Codicon.notebookTemplate = new Codicon('notebook-template', { fontCharacter: '\\ebbf' });
Codicon.debugRerun = new Codicon('debug-rerun', { fontCharacter: '\\ebc0' });
Codicon.workspaceTrusted = new Codicon('workspace-trusted', { fontCharacter: '\\ebc1' });
Codicon.workspaceUntrusted = new Codicon('workspace-untrusted', { fontCharacter: '\\ebc2' });
Codicon.workspaceUnspecified = new Codicon('workspace-unspecified', { fontCharacter: '\\ebc3' });
Codicon.terminalCmd = new Codicon('terminal-cmd', { fontCharacter: '\\ebc4' });
Codicon.terminalDebian = new Codicon('terminal-debian', { fontCharacter: '\\ebc5' });
Codicon.terminalLinux = new Codicon('terminal-linux', { fontCharacter: '\\ebc6' });
Codicon.terminalPowershell = new Codicon('terminal-powershell', { fontCharacter: '\\ebc7' });
Codicon.terminalTmux = new Codicon('terminal-tmux', { fontCharacter: '\\ebc8' });
Codicon.terminalUbuntu = new Codicon('terminal-ubuntu', { fontCharacter: '\\ebc9' });
Codicon.terminalBash = new Codicon('terminal-bash', { fontCharacter: '\\ebca' });
Codicon.arrowSwap = new Codicon('arrow-swap', { fontCharacter: '\\ebcb' });
Codicon.copy = new Codicon('copy', { fontCharacter: '\\ebcc' });
Codicon.personAdd = new Codicon('person-add', { fontCharacter: '\\ebcd' });
Codicon.filterFilled = new Codicon('filter-filled', { fontCharacter: '\\ebce' });
Codicon.wand = new Codicon('wand', { fontCharacter: '\\ebcf' });
Codicon.debugLineByLine = new Codicon('debug-line-by-line', { fontCharacter: '\\ebd0' });
Codicon.inspect = new Codicon('inspect', { fontCharacter: '\\ebd1' });
Codicon.layers = new Codicon('layers', { fontCharacter: '\\ebd2' });
Codicon.layersDot = new Codicon('layers-dot', { fontCharacter: '\\ebd3' });
Codicon.layersActive = new Codicon('layers-active', { fontCharacter: '\\ebd4' });
Codicon.compass = new Codicon('compass', { fontCharacter: '\\ebd5' });
Codicon.compassDot = new Codicon('compass-dot', { fontCharacter: '\\ebd6' });
Codicon.compassActive = new Codicon('compass-active', { fontCharacter: '\\ebd7' });
Codicon.azure = new Codicon('azure', { fontCharacter: '\\ebd8' });
Codicon.issueDraft = new Codicon('issue-draft', { fontCharacter: '\\ebd9' });
Codicon.gitPullRequestClosed = new Codicon('git-pull-request-closed', { fontCharacter: '\\ebda' });
Codicon.gitPullRequestDraft = new Codicon('git-pull-request-draft', { fontCharacter: '\\ebdb' });
Codicon.debugAll = new Codicon('debug-all', { fontCharacter: '\\ebdc' });
Codicon.debugCoverage = new Codicon('debug-coverage', { fontCharacter: '\\ebdd' });
Codicon.runErrors = new Codicon('run-errors', { fontCharacter: '\\ebde' });
Codicon.folderLibrary = new Codicon('folder-library', { fontCharacter: '\\ebdf' });
Codicon.debugContinueSmall = new Codicon('debug-continue-small', { fontCharacter: '\\ebe0' });
Codicon.beakerStop = new Codicon('beaker-stop', { fontCharacter: '\\ebe1' });
Codicon.graphLine = new Codicon('graph-line', { fontCharacter: '\\ebe2' });
Codicon.graphScatter = new Codicon('graph-scatter', { fontCharacter: '\\ebe3' });
Codicon.pieChart = new Codicon('pie-chart', { fontCharacter: '\\ebe4' });
Codicon.bracket = new Codicon('bracket', Codicon.json.definition);
Codicon.bracketDot = new Codicon('bracket-dot', { fontCharacter: '\\ebe5' });
Codicon.bracketError = new Codicon('bracket-error', { fontCharacter: '\\ebe6' });
Codicon.lockSmall = new Codicon('lock-small', { fontCharacter: '\\ebe7' });
Codicon.azureDevops = new Codicon('azure-devops', { fontCharacter: '\\ebe8' });
Codicon.verifiedFilled = new Codicon('verified-filled', { fontCharacter: '\\ebe9' });
Codicon.newLine = new Codicon('newline', { fontCharacter: '\\ebea' });
Codicon.layout = new Codicon('layout', { fontCharacter: '\\ebeb' });
Codicon.layoutActivitybarLeft = new Codicon('layout-activitybar-left', { fontCharacter: '\\ebec' });
Codicon.layoutActivitybarRight = new Codicon('layout-activitybar-right', { fontCharacter: '\\ebed' });
Codicon.layoutPanelLeft = new Codicon('layout-panel-left', { fontCharacter: '\\ebee' });
Codicon.layoutPanelCenter = new Codicon('layout-panel-center', { fontCharacter: '\\ebef' });
Codicon.layoutPanelJustify = new Codicon('layout-panel-justify', { fontCharacter: '\\ebf0' });
Codicon.layoutPanelRight = new Codicon('layout-panel-right', { fontCharacter: '\\ebf1' });
Codicon.layoutPanel = new Codicon('layout-panel', { fontCharacter: '\\ebf2' });
Codicon.layoutSidebarLeft = new Codicon('layout-sidebar-left', { fontCharacter: '\\ebf3' });
Codicon.layoutSidebarRight = new Codicon('layout-sidebar-right', { fontCharacter: '\\ebf4' });
Codicon.layoutStatusbar = new Codicon('layout-statusbar', { fontCharacter: '\\ebf5' });
Codicon.layoutMenubar = new Codicon('layout-menubar', { fontCharacter: '\\ebf6' });
Codicon.layoutCentered = new Codicon('layout-centered', { fontCharacter: '\\ebf7' });
Codicon.layoutSidebarRightOff = new Codicon('layout-sidebar-right-off', { fontCharacter: '\\ec00' });
Codicon.layoutPanelOff = new Codicon('layout-panel-off', { fontCharacter: '\\ec01' });
Codicon.layoutSidebarLeftOff = new Codicon('layout-sidebar-left-off', { fontCharacter: '\\ec02' });
Codicon.target = new Codicon('target', { fontCharacter: '\\ebf8' });
Codicon.indent = new Codicon('indent', { fontCharacter: '\\ebf9' });
Codicon.recordSmall = new Codicon('record-small', { fontCharacter: '\\ebfa' });
Codicon.errorSmall = new Codicon('error-small', { fontCharacter: '\\ebfb' });
Codicon.arrowCircleDown = new Codicon('arrow-circle-down', { fontCharacter: '\\ebfc' });
Codicon.arrowCircleLeft = new Codicon('arrow-circle-left', { fontCharacter: '\\ebfd' });
Codicon.arrowCircleRight = new Codicon('arrow-circle-right', { fontCharacter: '\\ebfe' });
Codicon.arrowCircleUp = new Codicon('arrow-circle-up', { fontCharacter: '\\ebff' });
Codicon.heartFilled = new Codicon('heart-filled', { fontCharacter: '\\ec04' });
Codicon.map = new Codicon('map', { fontCharacter: '\\ec05' });
Codicon.mapFilled = new Codicon('map-filled', { fontCharacter: '\\ec06' });
Codicon.circleSmall = new Codicon('circle-small', { fontCharacter: '\\ec07' });
Codicon.bellSlash = new Codicon('bell-slash', { fontCharacter: '\\ec08' });
Codicon.bellSlashDot = new Codicon('bell-slash-dot', { fontCharacter: '\\ec09' });
Codicon.commentUnresolved = new Codicon('comment-unresolved', { fontCharacter: '\\ec0a' });
Codicon.gitPullRequestGoToChanges = new Codicon('git-pull-request-go-to-changes', { fontCharacter: '\\ec0b' });
Codicon.gitPullRequestNewChanges = new Codicon('git-pull-request-new-changes', { fontCharacter: '\\ec0c' });
// derived icons, that could become separate icons
Codicon.dialogError = new Codicon('dialog-error', Codicon.error.definition);
Codicon.dialogWarning = new Codicon('dialog-warning', Codicon.warning.definition);
Codicon.dialogInfo = new Codicon('dialog-info', Codicon.info.definition);
Codicon.dialogClose = new Codicon('dialog-close', Codicon.close.definition);
Codicon.treeItemExpanded = new Codicon('tree-item-expanded', Codicon.chevronDown.definition); // collapsed is done with rotation
Codicon.treeFilterOnTypeOn = new Codicon('tree-filter-on-type-on', Codicon.listFilter.definition);
Codicon.treeFilterOnTypeOff = new Codicon('tree-filter-on-type-off', Codicon.listSelection.definition);
Codicon.treeFilterClear = new Codicon('tree-filter-clear', Codicon.close.definition);
Codicon.treeItemLoading = new Codicon('tree-item-loading', Codicon.loading.definition);
Codicon.menuSelection = new Codicon('menu-selection', Codicon.check.definition);
Codicon.menuSubmenu = new Codicon('menu-submenu', Codicon.chevronRight.definition);
Codicon.menuBarMore = new Codicon('menubar-more', Codicon.more.definition);
Codicon.scrollbarButtonLeft = new Codicon('scrollbar-button-left', Codicon.triangleLeft.definition);
Codicon.scrollbarButtonRight = new Codicon('scrollbar-button-right', Codicon.triangleRight.definition);
Codicon.scrollbarButtonUp = new Codicon('scrollbar-button-up', Codicon.triangleUp.definition);
Codicon.scrollbarButtonDown = new Codicon('scrollbar-button-down', Codicon.triangleDown.definition);
Codicon.toolBarMore = new Codicon('toolbar-more', Codicon.more.definition);
Codicon.quickInputBack = new Codicon('quick-input-back', Codicon.arrowLeft.definition);
export var CSSIcon;
(function (CSSIcon) {
    CSSIcon.iconNameSegment = '[A-Za-z0-9]+';
    CSSIcon.iconNameExpression = '[A-Za-z0-9-]+';
    CSSIcon.iconModifierExpression = '~[A-Za-z]+';
    CSSIcon.iconNameCharacter = '[A-Za-z0-9~-]';
    const cssIconIdRegex = new RegExp(`^(${CSSIcon.iconNameExpression})(${CSSIcon.iconModifierExpression})?$`);
    function asClassNameArray(icon) {
        if (icon instanceof Codicon) {
            return ['codicon', 'codicon-' + icon.id];
        }
        const match = cssIconIdRegex.exec(icon.id);
        if (!match) {
            return asClassNameArray(Codicon.error);
        }
        const [, id, modifier] = match;
        const classNames = ['codicon', 'codicon-' + id];
        if (modifier) {
            classNames.push('codicon-modifier-' + modifier.substr(1));
        }
        return classNames;
    }
    CSSIcon.asClassNameArray = asClassNameArray;
    function asClassName(icon) {
        return asClassNameArray(icon).join(' ');
    }
    CSSIcon.asClassName = asClassName;
    function asCSSSelector(icon) {
        return '.' + asClassNameArray(icon).join('.');
    }
    CSSIcon.asCSSSelector = asCSSSelector;
})(CSSIcon || (CSSIcon = {}));
